<template>
  <div>
    <el-row style='margin-top:5px'>
      <el-col :span='20'>
        <platforms-radio-group :showAll='true' :platformCode='searchCondition.platform_code'
                               @choicePlatform='choicePlatform'
                               @getPlatformsRange='getPlatformsRange'
                               style='margin-bottom: 5px'></platforms-radio-group>
      </el-col>
      <el-col :span='24'>
        <el-form size='mini' inline>
          <el-form-item>
            <el-select v-model='searchCondition.mode' placeholder='展示方式' style='width: 100px;'
                       clearable>
              <el-option value='month' label='按月汇总'></el-option>
              <el-option value='quarter' label='按季汇总'></el-option>
              <el-option value='year' label='按年汇总'></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-date-picker
              v-model='searchCondition.year'
              type='year'
              format='yyyy年'
              value-format='yyyy'
              placeholder='选择年度' style='width: 120px' @change='search'>
            </el-date-picker>
          </el-form-item>
          <el-form-item v-show='searchCondition.year'>
            <el-date-picker
              type='month'
              v-model='searchCondition.ym'
              format='yyyy年MM月'
              value-format='yyyy-MM'
              placeholder='选择月份' @change='search' style='width: 140px;'>
            </el-date-picker>
          </el-form-item>

          <el-form-item v-show='searchCondition.year'>
            <el-popover
              placement='right'
              title=''
              width='500'
              trigger='click'
            >
              <el-button type='default' slot='reference' @change='search'>
                选择季度<span v-if='searchCondition.quarter'>: 第 {{ searchCondition.quarter }} 季度</span>
              </el-button>
              <el-row>
                <el-col :span='24'>
                  <el-radio-group v-model='searchCondition.quarter'>
                    <el-radio-button v-for='(quarter) in quarters' :label='quarter.value' :key='quarter.label'>
                      {{ quarter.label }}
                    </el-radio-button>
                  </el-radio-group>
                </el-col>
              </el-row>
            </el-popover>
          </el-form-item>
          <el-form-item label=''>
            <el-date-picker
              v-model='searchCondition.monthRange'
              type='monthrange'
              align='right'
              unlink-panels
              range-separator='至'
              start-placeholder='开始月份'
              end-placeholder='结束月份'
              value-format='yyyy-MM'
              :picker-options='pickerOptions' @change='changeMonths'>
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <artist-search @handleSelect='chooseArtist' />
            <!--            <el-input type='text' placeholder='红人昵称检索' v-model='searchCondition.nickname' clearable></el-input>-->
          </el-form-item>
          <el-form-item>
            <el-button @click='getList' type='primary' :disabled='loadingStatus' :loading='loadingStatus'><i
              class='el-icon-search'></i>搜索
            </el-button>
          </el-form-item>
          <el-form-item>
            <export-btn ref='refExportLink' :lint-title='`导出`'
                        :can-export="userPermissions.indexOf('bs_month_summary_export')>-1"
                        @export='exportExcel'></export-btn>
          </el-form-item>

        </el-form>
      </el-col>
    </el-row>
    <div class='default-table'>
      <el-table :data='dataList' witdh='100%'
                :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
                @expand-change='expandChange' :summary-method='getSummaries'
                show-summary :default-sort="{prop: 'total_amount', order: 'ascending'}" border>
        <el-table-column type='expand' fixed='fixed' v-if='searchCondition.mode!=="month"'>
          <template slot-scope='{row}'>
            <month-summary-items :org-data='row.children' />
          </template>
        </el-table-column>
        <el-table-column type='index' align='center' width='80' label='序号'></el-table-column>
        <el-table-column prop='nickname' align='center' label='红人昵称' sortable>
          <template slot-scope='{row}'>
            <artist-nickname :info='{artist_id:row.artist_id,nickname:row.nickname}' />
          </template>
        </el-table-column>
        <el-table-column prop='duration' align='center' label='合作时长' sortable>
          <template slot-scope='{row}'>
            <el-tooltip placement='top' effect='light'>
              <div slot='content'>签约状态：{{ row.sign_status ? (row.sign_status == 'Y' ? '在约' : '解约') : '未知' }}<br />
                {{ row.sign_date ? $utils.parseTime(row.sign_date, '{y}-{m}-{d}') : '未知'
                }} ~ {{ row.fired_at ? $utils.parseTime(row.fired_at, '{y}-{m}-{d}') : '至今' }}
              </div>
              <span>{{ row.duration ? row.duration : '-'
                }} 个月 </span>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column prop='total_amount' align='right' header-align='center' label='总成交金额' sortable>
          <template slot-scope='{row}'>
            <span class='money'>{{ moneyFormat(row.total_amount) }}</span>
          </template>
        </el-table-column>
        <el-table-column prop='total_revenue' align='right' header-align='center' label='总收益额' sortable>
          <template slot-scope='{row}'>
            <span class='money'>{{ moneyFormat(row.total_revenue) }}</span>
          </template>
        </el-table-column>
        <el-table-column prop='income_rate' align='right' header-align='center' label='综合收益率' sortable>
          <template slot-scope='{row}'>
            <span class='rate'>{{ row.income_rate || '-' }}</span>
          </template>
        </el-table-column>
        <el-table-column prop='total_amount_live' align='right' header-align='center' label='总直播投放' sortable>
          <template slot-scope='{row}'>
            <span class='money'>{{ moneyFormat(row.total_amount_live) }}</span>
          </template>
        </el-table-column>
        <el-table-column prop='launch_rate' align='right' header-align='center' label='投放费比' sortable>
          <template slot-scope='{row}'>
            <span class='rate'>{{ row.launch_rate || '-' }}</span>
          </template>
        </el-table-column>
        <el-table-column prop='total_sessions' align='right' header-align='center' width='100'
                         label='直播场次' sortable></el-table-column>
      </el-table>
      <!--      分页-->
      <!--      <Pagination :limit.sync='pagingData.page_size'-->
      <!--                  :page.sync='pagingData.current_page'-->
      <!--                  :total='total'-->
      <!--                  @pagination='getList' />-->
    </div>
    <div style='font-size: 10px'>
        <pre>
      字段说明：

      1.总成交金额：本月红人所以直播场次成交金额之和（直播数据那边取数据）
      2.总收益额：本月该红人实际直播佣金与直播坑位之和，数据调取自次月内容团队录入数据（取营收数据的直播佣金和直播坑位费）
      3.综合收益率：总收益额/总成交金额
      4.总直播投放：本月该红人所有直播场次直播投放额之和（直播数据那边取数据）
      5.投放费比：总直播投放/总成交金额
      6.本月场次：本月该红人一共直播了几场
    </pre>
    </div>


  </div>
</template>

<script>
import MonthSummaryItems from './MonthSummaryItems'
import ArtistSearch from '@/components/artist/ArtistSearch'
import PlatformsRadioGroup from '@/pages/platform/components/PlatformsRadioGroup'
import ExportBtn from '@/components/export/ExportBtn'
import { mapGetters } from 'vuex'

export default {
  name: 'MonthSummary',
  components: { ExportBtn, ArtistSearch, MonthSummaryItems, PlatformsRadioGroup },
  computed: {
    ...mapGetters(['userPermissions'])
  },
  data() {
    return {
      dateTime: null,
      searchCondition: { year: null, ym: null, quarter: null, mode: 'month', monthRange: [] },
      orderSort: {
        total_amount: 'desc'
      },//默认排序规则
      // total: 0,
      // pagingData: {
      //   page_size: 15,
      //   current_page: 1
      // },
      quarters: [
        { label: '未选季度', value: 0 },
        { label: '第1季度', value: 1 },
        { label: '第2季度', value: 2 },
        { label: '第3季度', value: 3 },
        { label: '第4季度', value: 4 }
      ],
      dataList: [],
      expandRowKey: null,
      rangYm: [],
      pickerOptions: {
        shortcuts: [{
          text: '本月',
          onClick(picker) {
            picker.$emit('pick', [new Date(), new Date()])
          }
        }, {
          text: '今年至今',
          onClick(picker) {
            const end = new Date()
            const start = new Date(new Date().getFullYear(), 0)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近六个月',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setMonth(start.getMonth() - 5)
            picker.$emit('pick', [start, end])
          }
        }]
      },
      loadingStatus: false
    }
  },
  watch: {
    'searchCondition.year'() {
      this.searchCondition.mode = 'year'
      this.searchCondition.quarter = null
      this.searchCondition.monthRange = []
      this.searchCondition.ym = null
    },
    'searchCondition.ym'(newVal) {
      if (newVal) {
        this.searchCondition.quarter = null
        this.searchCondition.monthRange = []
        this.searchCondition.mode = 'month'
      }

    },
    'searchCondition.quarter'(newVal) {
      if (newVal) {
        this.searchCondition.ym = null
        this.searchCondition.monthRange = []
        this.searchCondition.mode = 'quarter'
      }
    }
    // 'searchCondition.monthRange'(newVal) {
    //   if (newVal) {
    //     this.searchCondition.ym = null
    //     this.searchCondition.quarter = null
    //
    //     this.searchCondition.mode = 'months'
    //   }
    // }
  },
  methods: {
    moneyFormat(value) { // 金额 格式化
      return this.$utils.numberFormat(value, 2, '.', ',', 'round')
    },
    chooseArtist(item) {
      if (item) {
        this.searchCondition.artist_id = item.id
      } else {
        this.searchCondition.artist_id = null
      }
      this.search()
    },
    choicePlatform(val) {
      if (val != '') {
        this.searchCondition.platform_code = val
      } else delete this.searchCondition?.platform_code
      this.search()
    },
    //获取平台的范围数据
    getPlatformsRange(val) {
      this.platforms = val
    },
    search() {
      this.getList()
    },
    async getList() {
      this.loadingStatus = true
      this.dataList = []
      // let params = Object.assign(this.searchCondition, this.pagingData, { order: this.orderSort })
      let params = Object.assign(this.searchCondition)
      let { list } = await this.$api.getBsMonthSummary(params)
      if (list && list.length > 0) {
        this.$nextTick(() => {
          this.dataList = list.map(item => {
            item['children'] = []
            return item
          })
        })
      }
      setTimeout(() => {
        this.loadingStatus = false
      }, 500)

    },
    changeMonths(val) {
      // console.log(val)
      if (val.length === 2) {
        this.search()
      }

    },
    getSummaries(param) {
      const { columns, data } = param
      const sums = []
      let total_revenue, total_amount, income_rate, total_amount_live, launch_rate
      columns.forEach((column, index) => {
        // if (index < 1) {
        //   return
        // }
        // if (index === 2) {
        //   sums[index] = '汇总:'
        //   return
        // }
        if (['total_amount', 'total_revenue', 'total_amount_live', 'total_sessions'].indexOf(column.property) > -1) {
          const values = data.map(item => Number(item[column.property]))
          if (!values.every(value => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr)
              if (!isNaN(value)) {
                return prev * 1 + curr * 1
              } else {
                return prev
              }
            }, 0)
            sums[index] += ''
          } else {
            sums[index] = 'N/A'
          }
          switch (column.property) {
            case 'total_revenue':
              total_revenue = sums[index]
              break
            case 'total_amount':
              total_amount = sums[index]
              break
            case 'total_amount_live':
              total_amount_live = sums[index]
              break
          }
          // 格式化数据
          switch (column.property) {
            case 'total_amount':
            case 'total_revenue':
            case 'total_amount_live':
              sums[index] = '￥' + this.$utils.numberFormat(sums[index], 2)
              break
            default:
              sums[index] = this.$utils.numberFormat(sums[index], 0)
              break
          }

        } else if (column.property === 'income_rate') {
          if (total_revenue && total_amount != 0) {
            income_rate = (total_revenue / total_amount) * 100
            sums[index] = this.$utils.numberFormat(income_rate, 2, '.', ',', 'round') + '%'

          } else sums[index] = '-%'
        } else if (column.property === 'launch_rate') {
          if (total_amount_live && total_amount != 0) {
            launch_rate = (total_amount_live / total_amount) * 100
            sums[index] = this.$utils.numberFormat(launch_rate, 2, '.', ',', 'round') + '%'

          } else sums[index] = '-%'
        }
      })

      return sums
    },
    async getItems(searchCondition) {
      let params = Object.assign(searchCondition)
      let { list } = await this.$api.getBsMonthSummaryItems(params)
      return list
    },
    /**
     * 记录当前展开页面
     * @param row
     */
    async expandChange(row) {
      this.expandRowKey = row.id
      let pIndex = this.dataList.indexOf(row)

      this.dataList[pIndex]['children'] = await this.getItems({
        artist_id: row.artist_id,
        nickname: row.nickname,
        mode: 'month',
        year: this.searchCondition.year,
        quarter: this.searchCondition.quarter,
        ym: this.searchCondition.ym
      })
    },
    //导出
    async exportExcel() {
      try {
        let name = `电商绩效-月度汇总`
        let params = Object.assign({}, this.searchCondition)
        let response = await this.$api.exportBsMonthSummary(params)
        if (this.$refs[`refExportLink`])
          this.$refs[`refExportLink`].exportSaveXlsx(response, name)
      } catch (e) {
        this.$message.warning('导出异常，请联系管理员')
      }
    }
  },
  created() {
    this.searchCondition.year = new Date().getFullYear() + ''
    this.searchCondition.mode = 'year'
    this.getList()
  }
}
</script>

