<template>
  <div>
    <div style='width: 98%;margin: auto'>
      <el-table :data='dataList' :summary-method='getSummaries'
                show-summary :default-sort="{prop: 'month', order: 'ascending'}" border>
        <el-table-column type='index' align='center' width='80' label='#'></el-table-column>
        <el-table-column prop='month' align='center' width='120' label='时间' sortable>
          <template slot-scope='{row}'>
            <span>{{ `${row.year}年${row.month}月` }}</span>
          </template>
        </el-table-column>
        <el-table-column prop='nickname' align='center' label='红人昵称' sortable>
        </el-table-column>
        <el-table-column prop='duration' align='center' label='合作时长' sortable>
          <template slot-scope='{row}'>
            <span>{{ row.duration ? row.duration : '-' }} 个月</span>
          </template>
        </el-table-column>
        <el-table-column prop='total_amount' align='right' header-align='center' label='总成交金额' sortable>
          <template slot-scope='{row}'>
            <span class='money'>{{ moneyFormat(row.total_amount) }}</span>
          </template>
        </el-table-column>
        <el-table-column prop='total_revenue' align='right' header-align='center' label='总收益额' sortable>
          <template slot-scope='{row}'>
            <span class='money'>{{ moneyFormat(row.total_revenue) }}</span>
          </template>
        </el-table-column>
        <el-table-column prop='income_rate' align='right' header-align='center' label='综合收益率' sortable>
          <template slot-scope='{row}'>
            <span class='rate'>{{ row.income_rate || '-' }}</span>
          </template>
        </el-table-column>
        <el-table-column prop='total_amount_live' align='right' header-align='center' label='总直播投放' sortable>
          <template slot-scope='{row}'>
            <span class='money'>{{ moneyFormat(row.total_amount_live) }}</span>
          </template>
        </el-table-column>
        <el-table-column prop='launch_rate' align='right' header-align='center' label='投放费比' sortable>
          <template slot-scope='{row}'>
            <span class='rate'>{{ row.launch_rate || '-' }}</span>
          </template>
        </el-table-column>
        <el-table-column prop='total_sessions' align='right' header-align='center' width='100'
                         label='本月场次' sortable></el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MonthSummaryItems',
  props: {
    orgData: {
      type: Array,
      default() {
        return []
      }
    }
  },
  computed: {
    dataList() {
      return [...this.orgData]
    }
  },
  methods: {
    moneyFormat(value) { // 金额 格式化
      return this.$utils.numberFormat(value, 2, '.', ',', 'round')
    },
    getSummaries(param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        // if (index < 1) {
        //   return
        // }
        // if (index === 0) {
        //   sums[index] = '汇总:'
        //   return
        // }
        if (['total_amount', 'total_revenue', 'total_amount_live', 'total_sessions'].indexOf(column.property) > -1) {
          const values = data.map(item => Number(item[column.property]))
          if (!values.every(value => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr)
              if (!isNaN(value)) {
                return prev * 1 + curr * 1
              } else {
                return prev
              }
            }, 0)
            sums[index] += ''
          } else {
            sums[index] = 'N/A'
          }
          // 格式化数据
          switch (column.property) {
            case 'total_amount':
            case 'total_revenue':
            case 'total_amount_live':
              sums[index] = '￥' + this.$utils.numberFormat(sums[index], 2)
              break
            default:
              sums[index] = this.$utils.numberFormat(sums[index], 0)
              break
          }
        }
      })

      return sums
    }
  }
}
</script>

<style scoped>

</style>